











import { Component, Vue } from 'vue-property-decorator';
import {EnvProvider} from '@/utilities';
import {getSiteUrl} from '@/utilities/getUrl';

@Component
export default class HomeCareBlock extends Vue {
  private homeCareLink: string = getSiteUrl(EnvProvider('URL_WEBSITE_HOME_CARE'));
}
