













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class HomeMissingDocumentsBlock extends Vue {
  @Prop({ type: String, required: true })
  private id!: string;
}
