


































import { Component, Vue } from 'vue-property-decorator';
import PolicyCard from '@/components/PolicyCard.vue';
import {IPolicyItem} from '@/interfaces';
import AddIcon from '@/assets/icons/add-big.svg';
import HomeMissingDocumentsBlock from '@/pages/Home/Home.missingDocuments.block.vue';
import {EnvProvider} from '@/utilities';

@Component({
  components: {
    PolicyCard,
    AddIcon,
    HomeMissingDocumentsBlock
  }
})
export default class HomePoliciesBlock extends Vue {
  private activeIndex = 0;
  private onboardingLink: string = EnvProvider('URL_WEBSITE');

  private sliderBreakpoints = {
    1001: {
      allowTouchMove: false
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 30,
      allowTouchMove: true
    }
  };
  private created() {
    this.$store.dispatch('getUserPolicies');
  }
  private get policiesList(): IPolicyItem[] {
    return this.$store.getters.getPolicies;
  }
  private get currentActivePolicy(): IPolicyItem {
    return this.policiesList[this.activeIndex];
  }
  private policyChanged($event) {
    this.activeIndex = $event.activeIndex;
  }
}
