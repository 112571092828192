




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IPerkItem} from '@/interfaces';

@Component
export default class HomePerksItem extends Vue {
  @Prop({type: Object, required: true})
  private slide!: IPerkItem;

  private openLink() {
    window.open(this.slide.link, '_blank');
  }
}
