












import {Component, Vue} from 'vue-property-decorator';
import {IPerkItem} from '@/interfaces';
import HomePerksItem from '@/pages/Home/Home.perks.item.vue';
import PrivilegesIcon from '@/assets/icons/perks/privileges-icon.svg';
import GarentiiBlogIcon from '@/assets/icons/perks/garentii-blog-icon.svg';
import ReferFriendIcon from '@/assets/icons/perks/refer-friend-icon.svg';
import {EnvProvider} from '@/utilities';
import {getSiteUrl} from '@/utilities/getUrl';

@Component({
  components: {HomePerksItem}
})
export default class HomePerksBlock extends Vue {
  public sliderBreakpoints = {
    1000: {
      slidesPerView: 3,
      spaceBetween: 20,
      allowTouchMove: false
    },
    700: {
      slidesPerView: 2.2,
      spaceBetween: 20,
      allowTouchMove: true
    },
    320: {
      slidesPerView: 1.2,
      spaceBetween: 20,
    }
  };

  public get itemsList(): IPerkItem[] {
    return [
      {
        title: this.$tc('home.perks.items.0.title'),
        text: this.$tc('home.perks.items.0.text'),
        icon: PrivilegesIcon,
        link: getSiteUrl(EnvProvider('URL_WEBSITE_PERKS')),
      },
      {
        title: this.$tc('home.perks.items.1.title'),
        text: this.$tc('home.perks.items.1.text'),
        icon: ReferFriendIcon,
        link: getSiteUrl(EnvProvider('URL_WEBSITE_REFER_FRIEND')),
      },
      {
        title: this.$tc('home.perks.items.2.title'),
        text: this.$tc('home.perks.items.2.text'),
        icon: GarentiiBlogIcon,
        link: getSiteUrl(EnvProvider('URL_WEBSITE_BLOG')),
      },
    ];
  }
}
