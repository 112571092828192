














import { Component, Vue } from 'vue-property-decorator';
import HomePoliciesBlock from '@/pages/Home/Home.policies.block.vue';
import HomeCareBlock from '@/pages/Home/Home.care.block.vue';
import HomePerksBlock from '@/pages/Home/Home.perks.block.vue';
import {i18n} from '@/main';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.home.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.home.description')
      }]
    };
  },
  components: {
    HomePoliciesBlock,
    HomeCareBlock,
    HomePerksBlock
  }
})
export default class HomePage extends Vue {
  private get getUserInfoName() {
    return this.$store.getters.getUserInfo
      ? this.$store.getters.getUserInfo.name?.split(' ')[0]
      : '';
  }
}
